import PropTypes from 'prop-types';
import { useContext, useRef, useState, useEffect } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import Button from "components/forms/Button";
import Input from "components/forms/Input";
import { CheckEmail } from 'services/myaccount/AuthContext.service';
import { useSearchParams } from 'next/navigation';
import Loader from 'components/feedback/Loader';
import clsx from 'clsx';

import utils from 'styles/globals/utils.module.scss';
import Style from "components/applications/my_account/styles/Auth.module.scss"

function AuthEmail({ setShown }){
    const [ global, dispatch ] = useContext(GlobalContext)
    const [ email, setEmail ] = useState("")
    const [ hasError,  setError ] = useState(false)
    const emailRef = useRef()
    const [disabled, setDisabled] = useState(false);
    const searchParams = useSearchParams();


    const handleEmail = (value) => {
        setEmail(value)
    }
       
    useEffect(() => {
          if (searchParams.get('emailID')) {
            setEmail(searchParams.get('emailID'));
        }   

    }, [])


    useEffect(() => {

        if (searchParams.get('emailID') && email.length) {
            saveEmail();
        }

    }, [email])

    const saveEmail = e => {
        if (e) {
           e.preventDefault();
        }

        if( /[^@\s]+@[^@\s]+\.[^@\s]+/i.test(email) === false ){
            setError(true);

        }else{
            setError(false);
            setDisabled(true);

            dispatch({
                type: "setEmail",
                data: {
                    email
                }
            });

    
            CheckEmail(email)
                .then((response) => {
                    if(response.data.exists){
                        setShown({ email: false, password: true })
                    
                    }else{
                        setShown({ email: false, password: false, register: true })
                    }
                
                }).catch((error) => {
                    setShown({ email: false, password: false, register: true })
                });
        }

    }


    return(
        <form onSubmit={saveEmail} method="post">
            <fieldset>
                <legend className={clsx(utils.fs_2, utils.text_center)}>
                    Enter your email address to sign in, or to create an account
                </legend>

                <Input 
                    label="Email Address"
                    placeholder="YourEmail@example.com"
                    name="email"
                    autoFocus
                    ref={emailRef}
                    type="email"
                    className={utils.my_3}
                    onKeyUp={handleEmail}
                    error={hasError}
                    helpText={hasError ? "Need that email address. Please try again." : ""}
                />
                
                <Button 
                    type="button" 
                    size="full"
                    color="ocean"
                    disabled={disabled}
                    className={Style.button}
                    onClick={saveEmail}
                >
                    {disabled ? <Loader isLoading hasError={hasError} /> : "Continue" }
                </Button>
            </fieldset>
        </form>
    )
}


AuthEmail.propTypes = {
    setShown: PropTypes.func
}


export default AuthEmail;