import { API, teakAPI } from 'services/AuthenteakApi';

const AuthHeader = (authData) => {
    if(!authData){
        return false;
    }
    return { headers: {"Authorization" : `Bearer ${authData.access_token}`} }
}


const AuthCheck = (authData, redirectIfGuest = true) => {
    try {
        if( !authData && redirectIfGuest && window ){
            window.location.href('/login?return='+window.location.pathname);
            return;
        }

        if(authData?.length){
            return authData;
        }

    } catch (e) {
        return false;
    }

    return false;
}



const CheckEmail = (email) => {
    return teakAPI.get("/api/teakAPI/accounts/emailExists/"+email);
}

const LoginUser = ( credentials ) => { //this is already server call used only in nextauth.js file
    return API.post("/login", credentials)
}

const GetAccount = (authData) => {//already server call
    let headers = AuthHeader(authData);
    return API.get("/accounts", headers)
}

const UpdateAccount = (authData, userData) => {
    let headers = AuthHeader(authData);
    return teakAPI.put("/api/teakAPI/accounts", userData, headers)
}

const GetAddresses = (authData) => { //already server call
    let headers = AuthHeader(authData); 
    return API.get("/accounts/addresses", headers) 
}

const GetAddress = (authData, address_id) => { //already server call
    let headers = AuthHeader(authData);
    return API.get("/accounts/addresses/"+address_id, headers)
}

const GetOrders = (authData) => { //already server call
    let headers = AuthHeader(authData);
    return API.get("/accounts/orders", headers)
}

const GetWishlists = (authData) => {
    let headers = AuthHeader(authData);
    return teakAPI.get("/api/teakAPI/accounts/wishlists", headers)
}

const GetWishlist = (authData, wishlist_id) => {
    let headers = AuthHeader(authData);
    return teakAPI.get("/api/teakAPI/accounts/wishlists/"+wishlist_id, headers)
}

const RemoveProductsFromWishlist = (authData, wishlist_id, product_ids) => {
    let headers = AuthHeader(authData);
    return teakAPI.post("/api/teakAPI/accounts/wishlists/"+wishlist_id+"/removeProductsFromList", product_ids, headers)
}

const AddProductsToWishlist = (authData, wishlist_id, product_ids) => {
    let headers = AuthHeader(authData);
    return teakAPI.post("/api/teakAPI/accounts/wishlists/"+wishlist_id+"/addProductsToList", product_ids, headers)
}

const GetOrder = (authData, order_id) => { // server call
    let headers = AuthHeader(authData);
    return API.get("/accounts/orders/"+order_id, headers)
}

const CreateAddress = (authData, address) => {
    let headers = AuthHeader(authData);
    return teakAPI.post("/api/teakAPI/accounts/addresses", address, headers)
}

const DeleteAddress = (authData, address_id) => {
    let headers = AuthHeader(authData);
    return teakAPI.delete("/api/teakAPI/accounts/addresses/"+address_id, headers)
}

const UpdateAddress = (authData, addressData) => {
    let headers = AuthHeader(authData);
    return teakAPI.put("/api/teakAPI/accounts/addresses/"+addressData.id, addressData, headers)
}

const CreateWishlist = (authData, wishlist) => {
    let headers = AuthHeader(authData);
    return teakAPI.post("/api/teakAPI/accounts/wishlists", wishlist, headers)
}

const LogoutUser = (authData) => { // server call
    let headers = AuthHeader(authData);
    return API.post("/logout", {}, headers)
}

const ForgotPassword = ( credentials ) => {
    return teakAPI.post("/api/teakAPI/forgotPassword", credentials)
}

const SendMagicLink = ( credentials ) => {
    return teakAPI.post("/api/teakAPI/sendMagicLink", credentials)
}

const RegisterUser = (userData) => {
    return teakAPI.post("/api/teakAPI/accounts", userData)
}

const UpdatePassword = (payload) => {
    return teakAPI.post("/api/teakAPI/updatePassword", payload)
}

const SignInWithToken = (payload) => { // server call
    return API.post("/loginWithToken", payload)
}


export {
    AuthCheck,
    CheckEmail,
    ForgotPassword,
    SendMagicLink,
    RegisterUser,
    GetAccount,
    UpdateAccount,
    GetOrder,
    GetWishlists,
    GetWishlist,
    AddProductsToWishlist,
    RemoveProductsFromWishlist,
    GetAddress,
    CreateAddress,
    UpdateAddress,
    DeleteAddress,
    GetOrders,
    CreateWishlist,
    GetAddresses,
    LoginUser,
    UpdatePassword,
    SignInWithToken,
    LogoutUser
}